<template>
  <div>
    <div class="header-table">
      <div class="form-action">
        <!-- filter desktop -->
        <b-row class="justify-content-end align-items-center mb-1">
          <b-col>
            <h4 class="font-medium mb-0">
              List Pixel
            </h4>
          </b-col>
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-end width-btn"
          >
            <b-button
              class="btn-submit"
              pill
              @click="handleCreateNew"
            >
              Create new
            </b-button>
          </b-col>
        </b-row>

        <!-- / filter desktop -->
      </div>
    </div>
    <vue-good-table
      class="pixel-table"
      mode="remote"
      :columns="columns"
      :rows="pixels"
      :is-loading="loading || localLoading"
      :pagination-options="{
        enabled: total > 0,
      }"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="d-flex align-items-center"
        >
          <b-link
            v-if="props.row.pixelName"
            class="underline text-primary font-weight-600 content-url"
            :href="`/pixel-management/${props.row._id}`"
            rel="noreferrer noopener"
          >
            {{ props.row.pixelName }}
          </b-link>
        </div>

        <!-- Column: ID -->
        <div
          v-else-if="props.column.field === 'id'"
          class="d-flex align-items-center justify-content-between"
        >
          <p class="mr-1 mb-0">
            {{ props.row.code }}
          </p>

          <feather-icon
            icon="CopyIcon"
            size="20"
            class="cursor-pointer icon-copy mr-1"
            @click="handleCopy(props.row.code)"
          />
        </div>

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'status'"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            :checked="getStatus(props.row.status)"
            name="check-button"
            switch
            @change="(checked) => handleChangeStatus(checked, props.row)"
          />
        </div>

        <!-- Column: Events -->
        <div
          v-else-if="props.column.field === 'events'"
          class="d-flex align-items-center"
        >
          <div v-if="props.row.configs">
            <img
              v-if="props.row.configs.enableViewContent"
              class="action-icon"
              :src="require('@/assets/images/icons/ic-eye.svg')"
              alt="image"
            >
            <img
              v-if="props.row.configs.enableAddToCart"
              class="action-icon"
              :src="require('@/assets/images/icons/ic-shopping-cart.svg')"
              alt="image"
            >
            <img
              v-if="props.row.configs.enableCompletePayment"
              class="action-icon"
              :src="require('@/assets/images/icons/ic-dollar-square.svg')"
              alt="image"
            >
            <img
              v-if="props.row.configs.enableInitiateCheckout"
              class="action-icon"
              :src="require('@/assets/images/icons/ic-card-tick.svg')"
              alt="image"
            >
          </div>
        </div>

        <!-- Column: Actions -->
        <div v-else-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="$router.push(`/pixel-management/${props.row._id}`)">
                <span> Edit </span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!props.row.code || !props.row.shopUrl"
                @click="handleTestEvent(props.row)"
              >
                <span> Test Event </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </div>
      </template>

      <!-- pagination -->
      <template
        v-if="total > 0"
        slot="pagination-bottom"
      >
        <div class="d-flex flex-wrap footer-tab footer-table">
          <b-col
            md="6"
            class="px-0 d-flex align-items-center"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                Pixel per page
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePerPage"
              />
              <span class="text-nowrap ">
                Showing
                {{ currentPage }}
                to
                {{ pageLength }}
                of {{ total }} pixel(s)
              </span>
            </div>
          </b-col>
          <!-- page length -->
          <b-col
            v-if="total > 10"
            md="6"
            class="px-0"
          >
            <b-pagination
              :total-rows="total"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </div>
      </template>
      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center"
      >
        <no-data-table-image class="my-5" />
      </div>
    </vue-good-table>
  </div>
</template>
<script>
import {
  BButton,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BLink,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import PixelMixin from '@/mixins/pixelMixin'
import envMixin from '@/mixins/envMixin'

const { mapGetters: mapGettersPixel } = createNamespacedHelpers('pixel')
const { mapActions: mapActionsPixel } = createNamespacedHelpers('pixel')

export default {
  directives: {
    Ripple,
  },

  components: {
    NoDataTableImage,
    BButton,
    BLink,
    BFormCheckbox,
    BRow,
    BFormSelect,
    BCol,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    VueGoodTable,
  },

  mixins: [PixelMixin, toastification, envMixin],

  data() {
    return {
      codeCopied: '',
      localLoading: false,
      currentPage: 1,
      searchTerm: '',

      // per page
      pageLength: 10,
      pages: ['5', '10', '25'],

      columns: [
        {
          label: 'Pixel Name',
          field: 'name',
          thClass: 'border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Pixel ID',
          field: 'id',
          thClass: 'border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Events',
          field: 'events',
          thClass: 'border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Action',
          field: 'actions',
          thClass: 'border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
    }
  },

  computed: {
    ...mapGettersPixel(['pixels', 'total', 'loading']),
  },

  watch: {
    codeCopied(value) {
      if (value) {
        this.toastSuccess(`Copied ${this.codeCopied}`)
      }
    },

    total(value) {
      if (!value) {
        this.$router.push('/pixel-management/create')
      }
    },
  },

  async created() {
    await this.getPixels({
      page: 1,
      size: 10,
    })
  },

  methods: {
    ...mapActionsPixel(['getPixels', 'changeStatus', 'deletePixel']),
    handleCopy(code) {
      this.$copyText(code)
        .then(() => {
          this.codeCopied = code
        })
      setTimeout(() => {
        this.codeCopied = ''
      }, 2000)
    },
    handleCreateNew() {
      this.$router.push('/pixel-management/create')
    },

    getStatus(status) {
      return status === 'RUNNING'
    },

    handlePerPage(page) {
      this.size = page
      this.pageLength = page
      this.currentPage = 1
      this.getPixels({
        page: 1,
        size: this.pageLength,
      })
    },

    handleChangePage(page) {
      this.size = page
      this.currentPage = page
      this.getPixels({
        page: this.size,
        size: this.pageLength,
      })
    },

    handleTestEvent(data) {
      if (!data.shopUrl || !data.code) {
        return
      }

      window.open(`https://${data.shopUrl}?test-pixel=true&pixelId=${data.code}`)
    },

    async handleChangeStatus(checked, data) {
      this.localLoading = true
      await this.changeStatus({
        // eslint-disable-next-line
        id: data._id,
        payload: {
          status: !checked ? 'PAUSED' : 'RUNNING',
        },
      })
      await this.getPixels({
        page: 1,
        size: 10,
      })
      this.localLoading = false
    },

    async handleDelete(data) {
      this.localLoading = true
      // eslint-disable-next-line
      await this.deletePixel(data._id)
      await this.getPixels({
        page: 1,
        size: 10,
      })
      this.localLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.pixel-table{
  .vgt-table{
    border: none;
  }
}

.footer-table {
  padding: 10px 24px 25px 24px;
  background-color: #fff;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-icon {
  width: 20px;

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.header-table {
  background-color: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px 24px;
}

.vgt-responsive {
  overflow: visible;
}

.custom-switch .custom-control-label:after {
  top: 5px;
}
.toastification-close-icon {
  display: none;
}
</style>
